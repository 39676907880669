@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;700;900&display=swap");

body {
  overflow-x: hidden;
  font-size: 1em;
  font-family: Montserrat, sans-serif;
  color: #707070;
}

.relative {
  position: relative;
}

.text-right {
  text-align: right;
}

.img-bg {
  width: 100%;
  height: auto;
}

.voucher h4 {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 2em;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
  -webkit-text-stroke: 1px #fff;
  -webkit-text-fill-color: #005485;
  font-family: Arial Black;
}

#voucher {
  max-height: 85vh;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid #fff;
  margin-bottom: -20px;
}

#voucher .logo {
  background: #005485;
  padding: 30px;
}

#voucher .nome-estabelecimento {
  background: #08476c;
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  padding: 15px;
}

#voucher .content-voucher {
  background: #f2f2f2;
  padding-top: 30px;
}

#voucher .logo-voucher {
  position: absolute;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  background: #fff;
  bottom: -50px;
  left: 20px;
  overflow: hidden;
  z-index: 10;
  border: 1px solid #707070;
}

#voucher .beneficio-voucher {
  background: #fff;
  padding: 20px 20px 20px 150px;
}

#voucher .regras-voucher {
  border-top: 1px solid #b1b1b1;
  border-bottom: 1px solid #b1b1b1;
  margin: 15px 0px;
}

#voucher .icone {
  background: #0f4c82;
  padding: 15px;
}

#voucher .descricao-regra {
  padding: 15px;
}

#voucher .caracteristicas-voucher {
  padding: 30px;
}

#voucher .horario {
  font-size: 0.9em;
}

.dia-semana {
  border: 1px solid #707070;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.5s ease;
  text-align: center;
  line-height: 40px;
  margin: 15px 0px;
}

.dia-aceito {
  border: 1px solid #1e5a2a;
  background: #1e5a2a;
  color: #fff;
}

#voucher .caracteristica {
  border: 1px solid #707070;
  padding: 10px;
  border-radius: 20px;
  margin: 5px;
}

.datas-voucher {
  font-size: 0.9em;
}

.aceitaferiado,
.aceitadata {
  margin-right: 10px;
  color: #1e5a2a;
}

.naoaceitaferiado,
.naoaceitadata {
  margin-right: 10px;
  color: #bd1622;
}

#voucher .dias-voucher {
  padding: 15px;
}

#voucher::-webkit-scrollbar {
  width: 0px;
}

/* Track */
#voucher::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
#voucher::-webkit-scrollbar-thumb {
  background: none;
}

/* Handle on hover */
#voucher::-webkit-scrollbar-thumb:hover {
  background: none;
}

.className {
  overflow-y: scroll;
  overflow-x: hidden;
}
#cadastro {
  padding: 40px 15px;
  color: #707070;
}

#cadastro h2 {
  color: #707070;
  font-weight: 700;
  font-size: 1.2em;
  margin-bottom: 20px;
}

#cadastro h3 {
  color: #707070;
  font-weight: 700;
  font-size: 1em;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.asterisco {
  color: #ad2e86;
}

.input-oqfc {
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  padding: 5px 10px;
  width: 100%;
}

.input-oqfc-select > div {
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  width: 100%;
}

.oqfc-input-container {
  margin-bottom: 20px;
}

.info-contrato {
  padding: 10px 15px;
}

.info-contrato h4 {
  color: #005485;
  font-weight: 500;
  font-style: italic;
  font-size: 1.2em;
}

.contatos-adicionais {
  margin-bottom: 20px;
}

.contatos-adicionais .input-oqfc {
  margin-bottom: 10px;
}

#dados-gerais {
  padding: 50px 15px;
  background: #005485;
  color: #fff;
}

#dados-gerais .input-select {
  color: #000;
}

#dados-gerais h4 {
  color: #f1bd00;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1.2em;
  margin-bottom: 20px;
}

#dados-gerais h3 {
  color: #fff;
  font-weight: 700;
  font-size: 1em;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.add-img {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.modal-crop,
.modal-error {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 50;
  padding: 20px;
}

.box-crop {
  background: #fff;
}

iframe {
  height: 90vh;
}

.close-modal {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: block;
}

.btn-close-modal {
  margin-right: 0px;
  cursor: pointer;
  color: #005485;
  font-weight: 900;
  font-size: 2em;
}

.infos-endereco {
  margin: 15px 0px;
}

.info-horario h4 {
  margin-top: 20px;
}

.horarios-dias,
.beneficio-dias {
  margin: 20px 0px;
}

.addUnidade {
  border: none;
  border-radius: 20px;
  padding: 5px 25px;
  text-transform: uppercase;
  background: #f1bd00;
  color: #005485;
  font-weight: 600;
  margin: 25px 0px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.addUnidade:hover {
  border-bottom: 3px solid #333;
  margin-top: 22px;
  margin-bottom: 28px;
}

.addHorarios {
  border: 2px solid #f1bd00;
  border-radius: 20px;
  padding: 5px 25px;
  text-transform: uppercase;
  background: none;
  color: #f1bd00;
  font-weight: 600;
  margin: 25px 0px 0px 0px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.addHorarios:hover {
  border-bottom: 4px solid #f1bd00;
  margin-top: 22px;
  margin-bottom: 3px;
}

#beneficio {
  padding: 50px 15px;
  background: #fff;
}

#beneficio h4 {
  color: #af3a82;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1.2em;
  margin-bottom: 20px;
}

#beneficio h5 {
  color: #af3a82;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 10px;
  font-style: italic;
}

#beneficio h3 {
  color: #707070;
  font-weight: 700;
  font-size: 1em;
  margin-bottom: 10px;
  text-transform: uppercase;
}

#beneficio .horarios-beneficio {
  margin: 25px 0px;
}

#beneficio input[type="checkbox"] {
  display: inline-block;
}

.addBeneficioHorarios {
  border: none;
  border-radius: 20px;
  padding: 5px 25px;
  text-transform: uppercase;
  background: #af3a82;
  color: #fff;
  font-weight: 600;
  margin: 25px 0px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.addBeneficioHorarios:hover {
  border-bottom: 3px solid #999;
  margin-top: 22px;
  margin-bottom: 28px;
}

button:focus {
  outline: none;
}

.aceita-beneficio {
  margin: 15px 0px;
}

.cardapio-upload {
  margin-top: 30px;
}

.btnEnviar {
  border: none;
  border-radius: 50px;
  padding: 15px 45px;
  text-transform: uppercase;
  background: #af3a82;
  color: #fff;
  font-weight: 600;
  margin: 50px 0px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 1.2em;
}

.btnEnviar:hover {
  border-bottom: 3px solid #999;
  margin-top: 48px;
  margin-bottom: 52px;
}

.btnSearch {
  margin-top: 20px;
}
.btnSearch:hover {
  margin-top: 22px;
}

.infos-error {
  padding: 30px;
  font-size: 0.9em;
}

.infos-error h5 {
  text-transform: uppercase;
  font-weight: 800;
}

.btn-modal-error {
  border: none;
  border-radius: 20px;
  padding: 5px 25px;
  text-transform: uppercase;
  background: #005485;
  color: #fff;
  font-weight: 600;
  margin: 25px auto 0px auto;
  transition: all 0.3s ease;
  cursor: pointer;
  display: table;
}

.btn-modal-success {
  background: #1e5a2a;
}

.btn-modal-error:hover {
  border-bottom: 3px solid #333;
  margin-top: 22px;
  margin-bottom: 3px;
}

/* CLASSE ERRO INPUT */

.error {
  color: #fff;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  border-radius: 0px 0px 10px 10px;
  padding: 15px 0px 5px 10px;
  margin-top: -30px;
  background: #9a0e0f;
}

/*------------------------------------------------------------------
---------------------------- RESPONSIVO ----------------------------
--------------------------------------------------------------------*/

@media (min-width: 768px) {
  .horarios-dias,
  .beneficio-dias {
    margin-right: 10px;
  }

  .horarios-dias:last-child,
  .beneficio-dias:last-child {
    border: none;
  }

  .info-horario {
    border-right: 1px solid #f1bd00;
  }
}

@media (min-width: 1200px) {
  .voucher {
    position: fixed;
    right: 0px;
    top: 0px;
  }

  .horarios-dias,
  .beneficio-dias {
    margin-right: 0px;
  }
}

@media (min-width: 1400px) {
  .horarios-dias,
  .beneficio-dias {
    margin-right: 10px;
  }
}

#spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -32px; /* half width of the spinner gif */
  margin-top: -32px; /* half height of the spinner gif */
  text-align: center;
  z-index: 1234;
  overflow: hidden;
  background-color: #ffffff;
  opacity: 0.8;
  filter: alpha(opacity=80); /* For IE8 and earlier */
  padding: 8px;
}

#map {
  height: 220px;
  width: 100%;
}
.beneficios {
  padding-top: 15px;
}
.horarios-dias button {
  margin-bottom: 25px !important;
}
.delUnidade {
  border: 2px solid #f1bd00;
  border-radius: 20px;
  padding: 5px 25px;
  text-transform: uppercase;
  background: none;
  color: #f1bd00;
  font-weight: 600;
  margin: 10px 0px 30px 0px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 0.8em;
}
.horarios-dias h3 {
  margin-top: 20px;
}

.blue-color {
  color: #005485;
}

.info-caracteristicas {
  margin-top: 30px;
}

.fast-option {
  white-space: nowrap;
}

input[type="file"],
input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}
.upload__content__input {
  height: 100%;
  margin: 0;
  opacity: 0;
  outline: none;
  padding: 0;
  position: absolute;
  width: 100%;
}

.btn.btn--primary {
  color: #0f4c81;
}
.btn.btn--outlined,
.btn.btn--primary {
  background-color: transparent;
}
.btn,
.w-auto {
  width: auto;
}

.btn.btn--primary:hover {
  background-color: #e8f3fc;
  color: #156cb7;
}

.btn.btn--filled.btn--primary {
  background-color: #0f4c81;
  color: #fff;
}

.btn.btn--filled.btn--primary:hover {
  background-color: #156cb7;
}

.modal-overlay {
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.modal-size-medium {
  width: 50vw !important;
}
.modal-box {
  background: #fff;
  border-radius: 1rem;
  display: block;
  padding: 1rem;
}

.pt-s-400 {
  padding-top: 2rem;
}
.row {
  display: flex;
  flex-flow: row wrap;
}

.justify-end {
  justify-content: flex-end;
}

.modal-close {
  cursor: pointer;
  float: right;
}

.upload__content {
  height: 100%;
  position: relative;
}

.upload__content,
.upload__filename {
  align-items: center;
  display: flex;
  justify-content: center;
}

.line {
  border-top: 1px solid #f1bd00;
  padding: 10px 0;
}

.lineUnits {
  border-top: 10px dotted #c2c2c2;
  margin: 30px 0;
}
